module.exports = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  helpers = helpers || Handlebars.helpers;
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, foundHelper;
  buffer += "\n            <span aria-hidden=\"true\" class=\"label\">\n                <span data-msg=\"label.player\"></span> <strong>";
  foundHelper = helpers.station;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.station; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "</strong>\n            </span>\n            <span class=\"aria-offscreen\" aria-label=\"Player ";
  foundHelper = helpers.station;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.station; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\"></span>\n            ";
  return buffer;}

function program3(depth0,data) {
  
  
  return "\n                <div id=\"cmhr-mc-game-question-countdown\" class=\"multiplayer-join\">\n                    <div id=\"cmhr-mc-game-countdown-animation\" class=\"countdown-timer\">\n                        <div class=\"spinner pie\"></div>\n                        <div class=\"filler pie\"></div>\n                        <div class=\"mask\"></div>\n                        <div class=\"overlay\">\n                            <span id=\"cmhr-mc-intro-countdown\" class=\"countdown\">10</span>\n                        </div>\n                    </div>\n                </div>\n\n                <h3 id=\"cmhr-mc-intro-multi-cta\" data-msg=\"label.multiplayer.cta\"></h3>\n                <h3 id=\"cmhr-mc-intro-multi-join\" data-msg=\"label.multiplayer.join\"></h3>\n                <h3 id=\"cmhr-mc-intro-multi-inprogress\" data-msg=\"label.multiplayer.inprogress\"></h3>\n                <a href=\"#/en/game/\" id=\"cmhr-mc-intro-multi\" data-msg=\"label.player.multi\" class=\"i18n btn btn-primary\"></a>\n                <a href=\"#/en/game/\" id=\"cmhr-mc-intro-single\" data-msg=\"label.player.single\" class=\"i18n btn btn-primary\"></a>\n            ";}

function program5(depth0,data) {
  
  
  return "\n                <a href=\"#/en/game/\" id=\"cmhr-mc-intro-single\" data-msg=\"label.player.play\" class=\"i18n btn btn-primary\"></a>\n            ";}

function program7(depth0,data) {
  
  
  return "\n            <span id=\"cmhr-mc-intro-multi-cta-aria\"  aria-hidden=\"true\" data-msg=\"label.multiplayer.cta\"></span>\n            <span id=\"cmhr-mc-intro-multi-join-aria\" aria-hidden=\"true\" data-msg=\"label.multiplayer.join\"></span>\n            <span id=\"cmhr-mc-intro-multi-inprogress-aria\" aria-hidden=\"true\" data-msg=\"label.multiplayer.inprogress\"></span>\n            <a href=\"#/en/game/\" tabindex=\"0\" id=\"cmhr-mc-intro-multi-aria\" data-msg=\"label.player.multi\" class=\"i18n btn btn-primary\"></a>\n            <a href=\"#/en/game/\" tabindex=\"0\" id=\"cmhr-mc-intro-single-aria\" data-msg=\"label.player.single\" class=\"i18n btn btn-primary\"></a>\n            ";}

function program9(depth0,data) {
  
  
  return "\n            <a href=\"#/en/game/\" tabindex=\"0\" id=\"cmhr-mc-intro-single-aria\"  data-msg=\"label.player.play\" class=\"i18n btn btn-primary\"></a>\n            ";}

  buffer += "<div class=\"cell left full-color\" role=\"banner\">\n    <div id=\"cmhr-mc-intro-text\" class=\"pad\">\n        <h1 id=\"intro-label\" data-msg=\"title\" tabindex=\"1\"></h1>\n        <h2 data-msg=\"subtitle\" tabindex=\"-1\"></h2>\n        <p data-msg=\"description\" tabindex=\"2\"></p>\n        <div class=\"social\" aria-hidden=\"true\">\n            <span class=\"share\" data-msg=\"label.intro.share\">Share:</span>\n            <div class=\"nowrap\">\n                <a href=\"#\" class=\"fa-stack fa-lg btn-share i18n-social\" data-vendor=\"twitter\" data-msg=\"share.intro.twitter\">\n                    <i class=\"fa fa-circle fa-stack-2x\"></i>\n                    <i class=\"fa fa-twitter fa-stack-1x fa-inverse\"></i>\n                </a>\n                <a href=\"#\" class=\"fa-stack fa-lg btn-share i18n-social\" data-vendor=\"facebook\" data-msg=\"share.intro.facebook\">\n                    <i class=\"fa fa-circle fa-stack-2x\"></i>\n                    <i class=\"fa fa-facebook fa-stack-1x fa-inverse\"></i>\n                </a>\n                <a href=\"#\" class=\"fa-stack fa-lg btn-email i18n-social\" data-msg=\"share.intro.mail\" >\n                    <i class=\"fa fa-circle fa-stack-2x\"></i>\n                    <i class=\"fa fa-envelope fa-stack-1x fa-inverse\" style=\"font-size: 16px; line-height: 36px;\"></i>\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n<div class=\"cell right\">\n    <div class=\"pad\">\n        <div class=\"flag\">\n            ";
  stack1 = depth0.gallery;
  stack1 = helpers['if'].call(depth0, stack1, {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        <div id=\"cmhr-mc-game-modes\" class=\"game-modes\" aria-hidden=\"true\">\n            ";
  stack1 = depth0.gallery;
  stack1 = helpers['if'].call(depth0, stack1, {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n\n        <div id=\"cmhr-mc-intro-aria-buttons\">\n            ";
  stack1 = depth0.gallery;
  stack1 = helpers['if'].call(depth0, stack1, {hash:{},inverse:self.program(9, program9, data),fn:self.program(7, program7, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n    </div>\n\n</div>\n";
  return buffer;});