var Attract, Question,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Question = require("models/question");

Attract = (function(_super) {

  __extends(Attract, _super);

  Attract.prototype.el = $("#cmhr-mc-attract");

  Attract.prototype.elements = {
    "#cmhr-mc-attract-slideshow .slideshow-wrapper": "slideshow"
  };

  Attract.prototype.events = {
    "click .language-selector": "onStartButtonClick"
  };

  Attract.prototype.station = -1;

  Attract.prototype.Open = "open";

  Attract.prototype.Closed = "closed";

  function Attract(station) {
    this.onStartButtonClick = __bind(this.onStartButtonClick, this);

    this.stopSlideshow = __bind(this.stopSlideshow, this);

    this.startSlideshow = __bind(this.startSlideshow, this);

    this.state = __bind(this.state, this);

    "Render View and Init Elements/Events";
    this.station = station;
    this.el.html(require('views/attract')({
      "questions": Question.all()
    }));
    Attract.__super__.constructor.apply(this, arguments);
  }

  Attract.prototype.state = function(to) {
    "Set the Components State (CSS Transitions)";

    var _this = this;
    this.el.attr("data-state", to);
    switch (to) {
      case this.Open:
        this.el.show();
        this.el.attr("aria-hidden", "false");
        this.el.find('#attract-start').focus();
        this.startSlideshow();
        if (this.station !== -1) {
          return Spine.trigger("game.multiplayer.leave", this.station);
        }
        break;
      case this.Closed:
        setTimeout(function() {
          return _this.el.hide();
        }, 1000);
        this.el.attr("aria-hidden", "true");
        return this.stopSlideshow();
    }
  };

  Attract.prototype.startSlideshow = function() {
    return this.slideshow.slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      draggable: false,
      fade: true,
      responsive: true,
      swipe: false,
      speed: 1000
    });
  };

  Attract.prototype.stopSlideshow = function() {
    var _this = this;
    return setTimeout(function() {
      try {
        return _this.slideshow.slick('unslick');
      } catch (e) {

      } finally {

      }
    }, 1000);
  };

  Attract.prototype.onStartButtonClick = function(e) {
    var elem;
    elem = $(e.currentTarget);
    return ga('send', 'event', 'button', 'click', 'attract-' + elem.attr('lang'));
  };

  return Attract;

})(Spine.Controller);

module.exports = Attract;
