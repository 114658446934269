var Question,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  __slice = [].slice;

Question = (function(_super) {

  __extends(Question, _super);

  function Question() {
    this.img = __bind(this.img, this);
    return Question.__super__.constructor.apply(this, arguments);
  }

  Question.configure("Question", "id");

  Question.extend(Spine.Model.Ajax);

  Question.url = "/api/questions.json";

  Question.prototype.img = function() {
    var first, last, rest, _i, _ref;
    _ref = this.media.fullImage.url.split('/'), first = _ref[0], rest = 3 <= _ref.length ? __slice.call(_ref, 1, _i = _ref.length - 1) : (_i = 1, []), last = _ref[_i++];
    return "/media/content/" + last;
  };

  Question.fromJSON = function(objects) {
    objects = objects.questions;
    return Question.__super__.constructor.fromJSON.call(this, objects);
  };

  return Question;

})(Spine.Model);

module.exports = Question;
