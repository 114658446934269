var Leaderboard,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Leaderboard = (function(_super) {

  __extends(Leaderboard, _super);

  function Leaderboard() {
    this.pk = __bind(this.pk, this);

    this.percent = __bind(this.percent, this);

    this.minutes = __bind(this.minutes, this);
    return Leaderboard.__super__.constructor.apply(this, arguments);
  }

  Leaderboard.configure("Config", "id", "name", "score", "time");

  Leaderboard.extend(Spine.Model.Ajax);

  Leaderboard.url = "/api/leaderboard.json";

  Leaderboard.prototype.minutes = function() {
    var m, s;
    m = Math.floor(this.time / 60);
    s = this.time - (m * 60);
    s = s < 10 ? "0" + s : s;
    return "" + m + ":" + s;
  };

  Leaderboard.prototype.percent = function() {
    var p;
    if (this.score === 0) {
      return this.score;
    } else {
      p = Math.floor((this.score / 500) * 100);
      if (p <= 100) {
        return p;
      } else {
        return 100;
      }
    }
  };

  Leaderboard.prototype.pk = function() {
    return this.id;
  };

  Leaderboard.sorted = function(a, b) {
    if (a.score > b.score) {
      return -1;
    } else {
      return 1;
    }
  };

  Leaderboard.fromJSON = function(objects) {
    objects = objects.leaderboard;
    objects = objects ? objects : [];
    return Leaderboard.__super__.constructor.fromJSON.call(this, objects);
  };

  return Leaderboard;

})(Spine.Model);

Handlebars.registerHelper('oneIndex', function(i) {
  return i + 1;
});

module.exports = Leaderboard;
