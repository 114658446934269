module.exports = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  helpers = helpers || Handlebars.helpers;
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, foundHelper;
  buffer += "\n        <div class=\"slide\" style=\"background: url(";
  foundHelper = helpers.img;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.img; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + ") no-repeat center center;\"></div>\n        ";
  return buffer;}

  buffer += "<section id=\"cmhr-mc-attract-slideshow\" class=\"slideshow fullbleed\" aria-labelledby=\"attract-label\">\n    <div id=\"attract-bg-image\"></div>\n    <div class=\"slideshow-wrapper\">\n        ";
  stack1 = depth0.questions;
  stack1 = helpers.each.call(depth0, stack1, {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    </div>\n</section>\n<div role=\"header\" id=\"attract-label\" role=\"navigation\">\n    <a id=\"attract-start\" tabindex=\"1\" href=\"#/en/intro/\" class=\"language-selector en\" aria-label=\"Begin the game 'Countdown to Confederation'\" lang=\"en\">\n        <span class=\"pad\">Countdown to Confederation</span>\n    </a>\n    <a href=\"#/fr/intro/\" tabindex=\"2\"  class=\"language-selector fr\" aria-label=\"Commencez le jez 'Compte &agrave; rebours vers la Conf&eacute;d&eacute;ration'\" lang=\"fr\">\n        <span class=\"pad\">Compte &agrave; rebours vers la Conf&eacute;d&eacute;ration</span>\n    </a>\n</div>\n";
  return buffer;});