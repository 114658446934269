var Loading,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Loading = (function(_super) {

  __extends(Loading, _super);

  "Application Loading Controller";


  Loading.prototype.el = $("#cmhr-mc-loading");

  function Loading() {
    this.inactive = __bind(this.inactive, this);

    this.active = __bind(this.active, this);
    this.el.html(require('views/loading'));
    Loading.__super__.constructor.apply(this, arguments);
    this.active();
  }

  Loading.prototype.active = function() {
    "Start Loading State";
    return this.el.show();
  };

  Loading.prototype.inactive = function() {
    "End Loading State";
    return this.el.hide();
  };

  return Loading;

})(Spine.Controller);

module.exports = Loading;
