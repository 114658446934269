var Intro,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

Intro = (function(_super) {

  __extends(Intro, _super);

  "Application Loading Controller";


  Intro.prototype.el = $("#cmhr-mc-intro");

  Intro.prototype.elGame = $("#cmhr-mc-game");

  Intro.prototype.elLeaderboard = $("#cmhr-mc-leaderboard");

  Intro.prototype.elements = {
    "#cmhr-mc-intro-aria-buttons": "ariaButtons",
    "#cmhr-mc-intro-countdown": "countdown",
    "#cmhr-mc-intro-text": "intro",
    "#cmhr-mc-game-countdown-animation": "countdownAnimation",
    "#cmhr-mc-game-modes": "modes",
    "#cmhr-mc-intro-multi": "multi",
    "#cmhr-mc-intro-single": "single",
    "#cmhr-mc-intro-multi-cta": "multiCta",
    "#cmhr-mc-intro-multi-inprogress": "multiInProgress",
    "#cmhr-mc-intro-multi-join": "multiJoin",
    "#cmhr-mc-intro-multi-aria": "multiAria",
    "#cmhr-mc-intro-single-aria": "singleAria",
    "#cmhr-mc-intro-multi-cta-aria": "multiCtaAria",
    "#cmhr-mc-intro-multi-inprogress-aria": "multiInProgressAria",
    "#cmhr-mc-intro-multi-join-aria": "multiJoinAria"
  };

  Intro.prototype.events = {
    "click .btn": "onButtonClick",
    "click #cmhr-mc-intro-multi": "onMultiplayerClick",
    "click #cmhr-mc-intro-single": "onSingleClick",
    "click .btn-share": "onShareButtonClick",
    "click #cmhr-mc-intro-multi-aria": "onMultiplayerClick",
    "click #cmhr-mc-intro-single-aria": "onSingleClick"
  };

  Intro.prototype.gallery = false;

  Intro.prototype.station = -1;

  Intro.prototype.queued = false;

  Intro.prototype.Open = "open";

  Intro.prototype.Closed = "closed";

  function Intro(station, gallery) {
    this.isAria = __bind(this.isAria, this);

    this.onSocketGameStart = __bind(this.onSocketGameStart, this);

    this.onSocketGameQueue = __bind(this.onSocketGameQueue, this);

    this.onSocketGameEnd = __bind(this.onSocketGameEnd, this);

    this.onSocketCountdown = __bind(this.onSocketCountdown, this);

    this.onSocketStatus = __bind(this.onSocketStatus, this);

    this.onSocketConnection = __bind(this.onSocketConnection, this);

    this.onResize = __bind(this.onResize, this);

    this.onShareButtonClick = __bind(this.onShareButtonClick, this);

    this.onSingleClick = __bind(this.onSingleClick, this);

    this.onMultiplayerClick = __bind(this.onMultiplayerClick, this);

    this.onButtonClick = __bind(this.onButtonClick, this);

    this.state = __bind(this.state, this);

    "Render View and Init Elements/Events";
    this.station = station;
    this.gallery = gallery;
    this.el.html(require('views/intro')({
      'gallery': this.gallery,
      'station': this.station
    }));
    Intro.__super__.constructor.apply(this, arguments);
    Spine.bind("socket.connection", this.onSocketConnection);
    Spine.bind("socket.game.status", this.onSocketStatus);
    Spine.bind("socket.game.countdown", this.onSocketCountdown);
    Spine.bind("socket.game.queue", this.onSocketGameQueue);
    Spine.bind("socket.game.end", this.onSocketGameEnd);
    Spine.bind("socket.game.start", this.onSocketGameStart);
    $(window).resize(this.onResize);
    this.onSocketGameEnd();
    this.state(this.Closed);
  }

  Intro.prototype.state = function(to) {
    "Set the Components State (CSS Transitions)";
    this.el.attr("data-state", to);
    switch (to) {
      case this.Open:
        Spine.trigger("game.multiplayer.status");
        this.el.attr("aria-hidden", "false");
        this.el.find('#intro-label').focus();
        return this.onResize(null);
      case this.Closed:
        return this.el.attr("aria-hidden", "true");
    }
  };

  Intro.prototype.onButtonClick = function(e) {
    if ($(e.currentTarget).hasClass("disabled")) {
      return e.preventDefault();
    } else if ($(e.currentTarget).parent('#cmhr-mc-intro-aria-buttons').length) {
      this.elGame.attr("data-aria", "true");
      return this.elLeaderboard.attr("data-aria", "true");
    }
  };

  Intro.prototype.onMultiplayerClick = function(e) {
    e.preventDefault();
    if (!this.queued) {
      this.queued = true;
      this.multi.addClass("disabled");
      if ($(e.currentTarget).parent('#cmhr-mc-intro-aria-buttons').length) {
        this.elGame.attr("data-aria", "true");
        this.elLeaderboard.attr("data-aria", "true");
      }
      Spine.trigger("game.multiplayer.join", this.station);
    }
    return ga('send', 'event', 'button', 'click', 'start-multiplayer' + this.isAria(e));
  };

  Intro.prototype.onSingleClick = function(e) {
    "If queued for multiplayer, remove from queue before entering single player";
    if (this.queued) {
      Spine.trigger("game.multiplayer.leave", this.station);
    }
    return ga('send', 'event', 'button', 'click', 'start-singleplayer' + this.isAria(e));
  };

  Intro.prototype.onShareButtonClick = function(e) {
    "Handle social sharing";

    var height, left, opts, top, url, width;
    e.preventDefault();
    width = 575;
    height = 400;
    left = ($(window).width() - width) / 2;
    top = ($(window).height() - height) / 2;
    url = e.currentTarget.href;
    opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
    return window.open(url, '', opts);
  };

  Intro.prototype.onResize = function(el) {
    var h, rh;
    if ($(window).width() > 550) {
      h = $(window).height() - $("#cmhr-desktop-header").height() - $("#cmhr-mc-nav").height();
      rh = h - this.el.find(".flag").height();
      this.intro.css("margin-top", (h / 2) - (this.intro.height() / 2));
      return this.modes.css("margin-top", (rh / 2) - (this.modes.height() / 2));
    }
  };

  Intro.prototype.onSocketConnection = function(msg) {
    "Set Socket Connection State";
    this.el.attr("data-connected", msg);
    if (msg) {
      return Spine.trigger("game.multiplayer.status");
    }
  };

  Intro.prototype.onSocketStatus = function(data) {
    "Update Panel State on Staus Request";
    this.el.attr("data-socket-state", data["status"]);
    if (data["status"] === "inactive") {
      this.queued = false;
      this.multi.removeClass("disabled");
      this.countdownAnimation.removeClass("run");
      this.multiCtaAria.attr("aria-hidden", "false");
      this.multiInProgressAria.attr("aria-hidden", "true");
      return this.multiJoinAria.attr("aria-hidden", "true");
    }
  };

  Intro.prototype.onSocketCountdown = function(msg) {
    "Update Countdown from IO Message";
    this.countdown.html(msg.countdown);
    this.multiCtaAria.attr("aria-hidden", "true");
    this.multiInProgressAria.attr("aria-hidden", "true");
    return this.multiJoinAria.attr("aria-hidden", "false");
  };

  Intro.prototype.onSocketGameEnd = function() {
    "On Game End: Set to Inactive";
    return this.el.attr("data-socket-state", "inactive");
  };

  Intro.prototype.onSocketGameQueue = function(msg) {
    "On Game Queue: Set to Queued";
    this.el.attr("data-socket-state", "queued");
    return this.countdownAnimation.addClass("run");
  };

  Intro.prototype.onSocketGameStart = function() {
    this.el.attr("data-socket-state", "active");
    return this.countdownAnimation.removeClass("run");
  };

  Intro.prototype.isAria = function(e) {
    var aria, elem, id;
    aria = '';
    elem = $(e.currentTarget);
    id = elem.attr("id");
    if (id.indexOf("aria") > -1) {
      aria = '-aria';
    }
    return aria;
  };

  return Intro;

})(Spine.Controller);

module.exports = Intro;
